/* IMPORT GOOGLE FONTS */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Oswald:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&display=swap');

/* Basics rest*/
*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* Custom properties*/
:root{
    
    --lato-light:300;
    --dark-color:#2d2c2c;
    /* template green*/ 
    --purple:#cc2290;
    --purple-dark:#981df0;
    --purple-transparent: rgba(204, 34, 144, 0.80);
    --purple-transparent-nav:#9d25ba;
    --purple-back: #5e15ad;
    --purple-dark-title:#4a1486;
    /* template blue*/
    --green:#28cfc5;
    --green-dark:#13547e;
    --green-transparent: rgba(40, 207, 197, 0.80);
    --green-transparent-nav:#28cec4;
    --green-back: #13547e;
    --green-dark-title: #0c3048;
    /* template orange*/
    --orange:#d9ae2c;
    --orange-dark:#d9ae2c;
    --orange-transparent: rgba(217, 174, 44, 0.80);
    --orange-transparent-nav:#d9ae2c;
    --orange-back: #8d151e;
    --orange-dark-title:#540d13;
}
/* GLOBAL STYLES */

body{
    font-family: 'Oswald', sans-serif;
    color: var(--dark-color);
}
a{
    text-decoration: none;
    color:inherit;
}
ul{
    list-style: none;
}

/* STYLES MENU */
.header{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.fa-bars{
    color:#ffffff !important;
}
.navbar-light .navbar-toggler {
    color: #ffffff !important;
    border-color: #ffffff !important;
}
.main-heading {
    position: relative;
    z-index: 1;
    padding: 35px 0 40px;
    vertical-align: middle;
    text-align: center;
    padding-top: 170px;
    padding-bottom:360px;
}
nav.sub {
	border-bottom: 1px solid #ebebeb;
	padding: 10px 0;
}
nav.main .nav-logo {
	max-width: 200px;
	width: 100%;
}
nav.main ul {
	padding-left: 0;
	list-style-type: none;
}
nav.main .megamenu {
	padding: 20px 20px;
	position: absolute;
	top: auto;
	left: 0;
	right: 0;
	max-width: 1500px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.megamenu{
    padding: 10px 40px 30px;
    margin: 0.125rem 1rem 0;
    border-radius: .50rem;
}
nav.main .midmenu {
	position: absolute;
	padding: 20px 20px;
	float: left;
	top: auto;
	left: auto;
	right: auto;
	max-width: 650px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.dropdown-menu.megamenu.midmenu.show {
    right: 0;
    
}
.dropdown-menu.show {
    right: 0;
   /* background-color: #d9ae2c;*/
    background-color: var(--green-transparent-nav);
}
.nav_item{
    transition: .25s;
    margin-left: 40px;
}
.nav_link{
    color:#ffffff !important;
    font-family: "Oswald", Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: 18px;
    padding: 25px 0 !important;
    transition: .2s;
}
.nav_link_sub{
    padding: 13px 10px 13px 0;
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    display: block;
    border-bottom: 1px solid #ededed;
    transition: 25ms;
    text-transform: uppercase;
}
/* HEADER STYLES SECTION */
.hero{
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    
}
.title-template{
    font-size: 2.1rem;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    font-weight: var(--lato-light);
    color:#ffffff;
    font-weight: 300;
    margin-bottom: 3.125rem;
}
.subtitle-template{
    color:#ffffff;
    font-size: 5.375rem;
    font-weight: 500;
    font-family: "Oswald", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-bottom: 3.125rem;
    
}
.text-title-template{
    color:#ffffff;
    font-family: 'Lato', sans-serif;
    font-weight: var(--lato-light);
    font-size: 1.875rem;
    margin-bottom: 3.375rem;
}
.dots-template{
    font-size: 2.4rem;
    margin-left: .5rem;
    opacity: .75;
}
.somos{
    margin-top:12rem ;
    padding-bottom: 12rem;
}
.banners{
    padding-top: 9rem;
    padding-bottom: 9em;
    position: relative;
    transition: .3s;
}

.desde{
    color:#fff;
    font-family: 'Lato', sans-serif;
    padding: .4rem 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.313rem;
}
.current{
    text-transform: uppercase;
}
.days{
    color: #fff;
    font-family: 'Lato', sans-serif;
}
.bloqueo{
    width: 80%;
    margin: auto;
}
.info-slide{
    position: absolute;
    bottom: 68px;
    right: 150px;
    text-align: right;
}
@media only screen and (max-width: 850px) {
    .info-slide{
        bottom: 28px;
        right: 10px;
    }
    .slide-desde{
        font-size: 25px;
    }
}

@media only screen and (max-width: 650px) {
   
    .text-title-template{
        font-size: 1.275rem; 
    }
}
.slide-title{
    color:#fff;
    font-weight: 600;
    font-family: "Oswald", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-size: 68px;
    line-height: 1;
}
.slide-desde{
    color:#fff;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 35px;
    line-height: 1;
    margin-bottom: 10px;
}
.logoMT{
  max-width: 40%;
}
.img3{
  width: 64%;
}
.iframe{
  border:0;
}
.slide-days{
    color:#fff;
    text-transform: uppercase;
    font-size: 19px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
   
}
.ofertas{
    width: 90% !important;
    margin: auto;
}
.ofertas .owl-prev {
    margin: 0 0 0 .7em;
    left: -65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}
.ofertas .owl-next {
    margin: 0 .7em 0 0;
    right: -65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}
.temporada{
    width: 90% !important;
    margin: auto;
}
.temporada .owl-prev {
    margin: 0 0 0 .7em;
    left: -65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}
.temporada .owl-next {
    margin: 0 .7em 0 0;
    right: -65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}
.favoritos{
    width: 90% !important;
    margin: auto;
}
.favoritos .owl-prev {
    margin: 0 0 0 .7em;
    left: -65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}
.favoritos .owl-next {
    margin: 0 .7em 0 0;
    right: -65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}
.bloqueos{
    width: 90% !important;
    margin: auto;
}
.bloqueos .owl-prev {
    margin: 0 0 0 .7em;
    left: -65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}
.bloqueos .owl-next {
    margin: 0 .7em 0 0;
    right: -65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}
.slide .owl-prev {
    margin: 0 0 0 .7em;
    left: 65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}
.slide .owl-next {
    margin: 0 .7em 0 0;
    right: 65px;
    display: inline-block;
    position: absolute;
    top: 45%;
    font-size: 2.7em !important;
    color: #ffffff !important;
    outline: none !important;
    background: transparent !important;
}

.traveler{
    margin-bottom: 7rem;
    margin-top: 7rem;
}
.megatv{
    margin-bottom: 7rem;
}
.buttons-download{
    margin-top: 2rem;
}
.buttons-see{
    margin-top: 2rem;
}
/*.button-template{
    transition: background 0.3s 0.01s, border-color 0.3s 0.01s;
    border-color:transparent;
    border: none;
    display: inline-block;
    max-width: 100%;
    min-width: 210px;
    font-family: "Oswald", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    border-radius: 35px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    transition: .25s all ease;
    font-size: 16px;
    padding: 17px 38px;
    text-decoration: none !important;
    color: #ffffff !important;
}*/

/* STYLES FOOTER */
.footer{
    background-color: #29293a;
}
.title-footer{
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
    font-size: 2.2rem;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
}
.paragraph-footer{
    color: #fff;
    padding: 0 0 0 2rem;
    position: relative;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    line-height: 1.8;
    margin-bottom: 3.5rem;
}
.span-market{
    position: absolute;
    left: 0;
}
.paragraph-envelope{
    color: #fff;
    margin-top: 9.5rem;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
}
.paragraph-mobile{
    color: #fff;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
}
.span-envelope{
    position: relative;
    display: inline-block;
    margin-right: 10px;
}
.span-mobile{
    position: relative;
    display: inline-block;
    margin-right: 15px;
}
.title-form{
    
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 1.7rem;
    margin-top: 1.7rem;
}
.form-group-footer{
    margin-bottom: 0rem;
}
.label-footer{
    color: #fff;
    padding-right: 0;
    font-size: 19px;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
}
.input-footer{
    background:transparent !important;
    border: 0px !important;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 0px;
    font-size: 19px;
    color: #fff !important;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
    height: 30px;
}
.textarea-footer{
    height: 104px !important ;
    border:0 !important;
    font-size: 2rem;
    border-radius: 0px !important;
    outline: none !important;
    color: #000 !important;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
}
.social-icons-list{
    display: inline-block;
    margin: 0;
    width: 100%;
    text-align: right;
}

.social-icons-list li{
    display: inline-block;
    margin-top: 0;
    margin-bottom: 9px;
    margin-left: 9px;
}
.social-icons-list li a{
    width: 1.9em;
    height: 1.9em;
    line-height: 1.9em;
    color: #c2c2c3;
    background-color: #21212e;
    font-size: 20px;
    display: inline-block;
    text-align: center;
}
.icon-circle {
    border-radius: 100%;
}
.meca{
    text-align: center;
    color: #fff;
    font-size: 1.7;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
    margin-top: 3rem;
}
/* Reusabel styles */
.container-template{
    width: 100%;
    max-width: 1400px;
    padding: 0 1.5rem;
    margin: 0 auto;
}
.container-title{
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 8rem;
    margin-bottom: 3rem;
}
.container-title-white{
    position: relative;
    display: inline-block;
    width: 100%;
  
}
.way-point {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% - 2px);
    display: flex;
    cursor: pointer;
}
.way-point .icon {
    font-size: 30px;
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) all;
}
.title-black{
    color: #000;
    font-weight: 400;
    font-size: 2.125em;
    font-family: "Oswald", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
    position: relative;
}
.title-white{
    color: #fff;
    font-weight: 500;
    font-size: 2em;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
    position: relative;
}
.back-title{
    position: absolute;
    left: 5px;
    top: 2px;
    color: #f1f2f9;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    letter-spacing: .28em;
    text-transform: uppercase;
    font-size: 6.875em;
    display: inline-block;
    width: 100%;
    line-height: 1;
}
.back-title-black{
    position: absolute;
    left: 5px;
    top: -15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    letter-spacing: .28em;
    font-size: 6.5em;
    line-height: 1;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
}
.paragraph-lato-regular{
    color: #000;
    font-weight: 400;
    font-size: 19px;
    font-family: 'Lato', sans-serif;
    margin-bottom: 2rem;
    
}
.paragraph-lato-light{
    color: #000;
    font-weight: 300;
    font-size: 19px;
    font-family: 'Lato', sans-serif;
    margin-bottom: 5.1rem;
    
}
.paragraph-oswald-light{
    color: #000;
    font-weight: 300;
    font-size: 19px;
    font-family: 'Oswald', sans-serif;
    text-align: justify;
    line-height: 1.8;
}
.line{
    height: 3px;
    display: block;
    width: 100px;
    margin-bottom: 3rem;
}
.line-dark{
    height: 3px;
    display: block;
    width: 100px;
    margin-top:3rem;
    margin-bottom: 3rem;
}
.item-info{
    position: relative;
}
.info{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}
.btn-info-template{
    transition: background 0.3s 0.01s, border-color 0.3s 0.01s;
    background-color: transparent;
    border: 2px solid #ededed;
    display: inline-block;
    max-width: 100%;
    min-width: 210px;
    font-family: "Oswald", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    border-radius: 35px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    transition: .25s all ease;
    font-size: 16px;
    padding: 17px 38px;
    text-decoration: none !important;
    color: #000000 !important;
}
/* REUTILISABLE VERSIONS TEMPLATES */
.color-purple{
    color: var(--purple);
}
.header-purple{
    background-image: url('https://exodus.b-cdn.net/exodus/magno/01/img/1_Template-1.png');
}
.nav-link-purple:hover{
    color: var(--purple) !important;
    text-decoration: underline;
}
.back-purple{
    background-color: var(--purple-back);
}
.purple_nav{
    background-color: var(--purple-transparent-nav);
}
.button-purple{
    background-color:var(--purple);
}
.button-purple:hover{
    background-color: var(--purple-dark);
    text-decoration:none;
}
/*.button-white:hover{
    background-color: var(--purple-dark);
    border: 2px solid var(--purple-dark);
    color: #fff !important;
    text-decoration: none;
}*/
.purple-color{
    background-color: var(--purple); 
}
.purple-dark{
    background-color: var(--purple-dark);
}
.purple-transparent{
    background-color: var(--purple-transparent);
}
.purple-input{
    border-bottom: 1px solid var(--purple) !important;
}
.way-point-purple{
    fill:var(--purple-back);
}
.back-text-title-purple{
    color: var(--purple-dark-title);
}
/* REUTILISABLE VERSIONS TEMPLATES */
.color-green{
    color: var(--green);
}
.header-green{
    background-image: url('https://exodus.b-cdn.net/exodus/magno/02/img/1_Template-1.png');
}
.nav-link-green:hover{
    color: var(--green-dark) !important;
    text-decoration: underline;
}
.back-green{
    background-color: var(--green-back);
}
.green_nav{
    background-color: var(--green-transparent-nav);
}
.button-green{
    background-color:var(--green);
}
.button-green:hover{
    background-color: var(--green-dark);
    text-decoration:none;
}
.button-white:hover{
    background-color: var(--green-dark);
    border: 2px solid var(--green-dark);
    color: #fff !important;
    text-decoration: none;
}
.green-color{
    background-color: var(--green); 
}
.green-dark{
    background-color: var(--green-dark);
}
.green-transparent{
    background-color: var(--green-transparent);
}
.green-input{
    border-bottom: 1px solid var(--green) !important;
}
.way-point-green{
    fill:var(--green-back);
}
.back-text-title-green{
    color: var(--green-dark-title);
}
/* REUTILISABLE VERSIONS TEMPLATES */
.color-orange{
    color: var(--orange);
}
.header-orange{
    background-image: url('https://exodus.b-cdn.net/exodus/magno/03/img/1_Template-1.png');
}
.nav-link-orange:hover{
    color: var(--orange-back) !important;
    text-decoration: underline;
}
.back-orange{
    background-color: var(--orange-back);
}
.orange_nav{
    background-color: var(--orange-transparent-nav);
}
.button-orange{
    background-color:var(--orange);
}
.button-orange:hover{
    background-color: var(--orange-back);
    text-decoration:none;
}
/*.button-white:hover{
    background-color: var(--orange-dark);
    border: 2px solid var(--orange-dark);
    color: #fff !important;
    text-decoration: none;
}*/
.orange-color{
    background-color: var(--orange); 
}
.orange-dark{
    background-color: var(--orange-dark);
}
.orange-transparent{
    background-color: var(--orange-transparent);
}
.orange-input{
    border-bottom: 1px solid var(--orange) !important;
}
.way-point-orange{
    fill:var(--orange-back);
}
.back-text-title-orange{
    color: var(--orange-dark-title);
}
/* BREAK POINTS */
@media only screen and (max-width: 1100px) {
    .nav_link{
        font-size: 14px;
    }
}

@media only screen and (max-width: 992px) {
    .navbar-responsive-purple{
        background-color: var(--purple-transparent-nav);
    }
    .navbar-responsive-green{
        background-color: var(--green-transparent-nav)
    }
    .navbar-responsive-orange{
        background-color: var(--orange-transparent-nav)
    }
    .back-title-black{
        font-size: 5rem;
    }
    
}
@media only screen and (max-width: 870px) {
    .back-title{
        font-size: 4.8rem;
    }
    
}

@media only screen and (max-width: 768px) {
    .megamenu{
        max-height: 300px;
        overflow: auto;
    }
}

@media only screen and (max-width: 740px) {
    .back-title-black{
        font-size: 3rem;
    }
    .back-title{
        font-size: 3rem;
    }
    .iframe{
        width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .subtitle-template{
        font-size: 2.375rem;
    }
    .back-title-black{
        font-size: 2.5rem;
        left: -5px;
    }
}

@media only screen and (max-width: 425px) {
    
    .subtitle-template{
        font-size: 2.375rem;
    }
    .back-title-black{
        font-size: 2rem;
        left: -5px;
    }
    .back-title{
        font-size: 2rem;
        left: -5px;
    }
    .button-template{
        margin-bottom: 20px;
    }
    .btn-info-template{
        margin-bottom: 20px;
    }
    .nav_item {
        transition: .25s;
        margin-left: 10px;
    }
    .megamenu {
        padding: 10px 30px 20px;
        margin: 0.125rem 0rem 0;
        border-radius: .50rem;
        width: 100%;
    }
}


 
.ofertas .slick-slide{
    padding:10px;
    }
    
.ofertas .slick-slide .item img{
    width:100%;
    }

 .bloqueos .slick-slide{
     padding:10px;
        }
        
 .bloqueos .slick-slide .item img{
    width:100%;
        }
  .favoritos .slick-slide{
     padding:10px;
            }
            
 .favoritos .slick-slide .item img{
  width:100%;
            }

.writing{
    display:none;
}

.icon-whats{
    position: fixed;
    top: 84%;
    right: 10px;
    z-index: 9999;
    width: 50px;
    height: 50px;
}

.background-section-green{
    background-color: var(--green-transparent-nav);
}
.slide_movil {
    display: none!important;
  }

  @media only screen and (max-width: 650px) {
    .slide_movil {
      display: block!important;
      width: 100%;
  }
  .slide_desktop{
    display: none!important;
  }
  
  }